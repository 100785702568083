<template>
  <form class="code-page" novalidate @submit.prevent="validate">
    <Title class="code-page__title"> Такой аккаунт уже существует </Title>
    <p class="code-page__desc">
      Номер телефона уже используется. Для продолжения введите код из СМС или
      используйте другой номер телефона для регистрации.
    </p>
    <div class="code-page__form">
      <Code
        ref="code"
        type="before"
        :getCode="getCode"
        @ready="submit"
        notInitSend
      />
      <div class="code-page__actions">
        <Button type="submit"> Продолжить </Button>
        <Button @click.native="back" secondary> Назад </Button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import mask from "@index/helpers/string/setMask";

import Code from "@index/components/common/code/Code";
import Title from "@index/components/gui/title/Title";
import Button from "@index/components/gui/button/Button";

import store from "@index/store";

import "./code-page.scss";

export default {
  computed: {
    ...mapGetters({
      user: ["application/user"],
      code_hash: ["application/code"],
    }),
    code() {
      return this.$refs.code?.form?.code;
    },
  },
  methods: {
    back() {
      this.$router.push({ name: "ContactInfo" });
    },
    async getCode() {
      await this.$store.dispatch("application/send", {
        contactData: this.user.contactData,
      });
    },
    validate() {
      this.$refs.code.validate();

      this.$refs.code.isValid && this.submit();
    },
    async submit() {
      const { wrongCode } = await this.$store.dispatch("application/send", {
        contactData: {
          ...this.user.contactData,
          code_hash: this.code_hash,
          code: this.code,
        },
      });

      if (!wrongCode) {
        await this.$store.dispatch("application/update");
        this.$store.commit("application/clearCode");
        this.$refs.code.resetTimer();
        window.location.href = "/poll/passport/";
      } else {
        this.$refs.code.validate();
        this.$refs.code.formErrors.code = "Неверный код";
      }
    },
  },
  components: {
    Code,
    Title,
    Button,
  },
  filters: {
    mask,
  },
  beforeRouteEnter(to, from, next) {
    // @TODO внедрить проверку на разрешенный переход на эту страницу
    const code = store.getters["application/code"];

    if (code) {
      next();
    } else {
      store.commit("application/load", false);
      next({ name: "ContactInfo" });
    }
  },
};
</script>
